/*
*  Author: Marton Szeles
*  Copyright 2020
*/

@font-face {
    font-family: 'NotoSans-Regular';
    src: url('./marton/fonts/NotoSans/NotoSans-Regular.ttf');
}

@font-face {
    font-family: 'NotoSans-Bold';
    src: url('./marton/fonts/NotoSans/NotoSans-Bold.ttf');
}

@font-face {
    font-family: 'Quicksand';
    src: url('./marton/fonts/Quicksand/Quicksand-Medium.ttf');
}

/* For small mobile phones: */
@media only screen and (max-width: 500px) { 
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
    .main_container{
        padding-bottom: 60px;
    }
    .only-desktop{
        display: none !important;
    }
    h1 {
        margin-bottom: 15px;
    }
    .menu {
        text-align: center;
    }
    .logo{
        font-size: 16px;
        width: 100%;
    }
    .dashboard{
        letter-spacing: 0px;
        display: inline;
    }
    .dashboard ul{
        width: 100%;
        text-align: center;
    }
    .dashboard ul li{
        list-style: none;
        width: 25%;
        float: left;
    }
    .dashboard ul li .dashboard_tabs {
        font-size: 16px;
    }  

    .content{
        width: 100%;
    }

    /* ABOUT */
    .profile{
        padding-bottom: 30px;
    }
}

/* For desktop: */
@media only screen and (min-width: 769px) {
    .only-mobile{
        display: none !important;
    }
    h1 {
        margin-bottom: 30px;
    }

    .main_container{
        padding-top: 90px;
        margin-bottom: 90px;
    }

    .menu {
        position: fixed;
        display: table;
        padding: 10px 100px 10px 100px;
    }

    .logo{
        font-size: 24px;
        display: table-cell;
        text-align: left;
        padding-left: 30px;
    }
    .dashboard{
        display: table;
        float: right;
        letter-spacing: 1px;
    }
    .dashboard ul {
        width: 1024px;
    }
    .dashboard ul li{
        list-style: none;
        width: 25%;
        float: left;
        margin: 0;
        padding: 0;
    }
    .dashboard ul li .dashboard_tabs {
        font-size: 20px;
    }
    .dashboard ul:after {
        margin: 0 0 0 1023px;
    }

    .content{
        margin: 90px 100px 50px 100px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    /* ABOUT */
    .profile{
        display: flex;
    }
    
    .profile-img-container{
        order: 1
    }
    
    .profile-description-container{
        order: 2;
    }

    .profile-description{
        margin-top: 15%;
    }

    .profile-half{
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }

    /* CONTACT */
    .contact {
        margin-top: -5px;
        padding-top: 5px;
        margin-bottom: -20px;
        width: 50%;
    }

    .contact_tile {
        margin: auto 25px auto 25px;
    }

    .copyright {
        width: 1024px;
    }
}

body, html {
    max-width: 100%;
    height: 100%;
}

body {
    margin: 0;
    overflow-y: scroll;
    /* No idea what's overflowing, but this (hides) gets rid of the problem. */
    /* overflow-x: hidden; */
    font-family: NotoSans-Regular;
    font-size: 16px;
}

* {
    margin: 0;
    padding: 0;
}

.app{
    height: 100%;
}

/* Menu */
  .bar1, .bar2, .bar3 {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .toggled .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7.5px, 6px);
    transform: rotate(-45deg) translate(-7.5px, 6px);
  }
  
  .toggled .bar2 {
    opacity: 0;
  }
  
  .toggled .bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
  }

.mobile-menu{
    margin-top: 25%;
    padding-top: 10%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
}
.dashboard .mobile-menu ul{
    display: inline-block;
    width: 98%;
    overflow: hidden;
    text-align: center;
    padding: 5px;
}

/* Background Gallery */
.disable-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.c-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.c-move {
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 0.6;
}

.c-move:hover {
    opacity: 1;
}

.c-left {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 100;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background: url(./marton/images/src/arrow.png) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.c-right {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 100;
    background: url(./marton/images/src/arrow.png) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.c-exit {
    width: 20px;
    height: 20px;
    background: url(./marton/images/src/exit.png) center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    cursor: pointer;
    opacity: 0.6;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
}

.c-exit:hover {
    opacity: 1;
}

.background-container {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.dark-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgb(31, 31, 31);
    transition: "all .5s ease";
    -webkit-transition: "all .5s ease";
    -moz-transition: "all .5s ease";
}

.loading-background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-image: url(./marton/images/src/loading.gif);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 160px 20px;
}

.background-pic {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-attachment: fixed;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.visible {
    display: inherit;
}

.invisible {
    display: none;
}

.almostFullOpacity {
    opacity: 0.9;
    transition: opacity 500ms ease-out;
}

.zeroOpacity {
    opacity: 0 !important;
    transition: opacity 500ms ease-in;
}

.fullOpacity {
    opacity: 1;
    transition: opacity 500ms ease-out;
}

.bg-scale-screen {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-color: white;
}

.bg-fill-screen {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.lowerOpacity {
    opacity: 0.8 !important;
}

a {
    color: #333;
    text-decoration: none;
}

a:visited {
    color: #333;
    text-decoration: none;
}

h1 {
    font-size: 24px;
    text-align: left;
    color: rgb(55 50 105);
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgb(230, 230, 230);
    margin-left: 20px;
    margin-right: 20px;
}

.logo {
    vertical-align: middle;
    font-family: 'Quicksand';
    line-height: 50px;
    height: 50px;
    letter-spacing: 2px;
}

.logo a {
    color: rgb(55 50 105);
}


.menu {
    box-sizing: border-box;
    width: 100%;
    top: 0;
    z-index: 10;
    background: white;
}

.menu-item{
    padding: 0 30px 0 20px;
    display: table-cell;
    vertical-align: middle;
    height: 40px;
}

.menu-item .active{
    color: rgb(101 94 167);
    font-weight: bold;
}

.dashboard {
    vertical-align: middle;
    background: grey;
    min-height: 70px;
    text-align: center;
    background: url(./marton/images/src/dashboard.png) repeat-x;
    overflow-x: hidden;
    color: #333;
}

.content {
    position: relative;
    z-index: 0;
    text-align: center;
}

.height-100{
    height: 100%;
}

.container{
    min-height: 100%;
    margin: 0 auto -50px;
}

.shadow {
    box-shadow: 0 0px 22px rgba(55, 50, 105, 0.5);
}

.copyright {
    box-sizing: border-box;
    text-align: center;
    font-size: 11px;
    color: rgba(218, 218, 218, 0.6);
    padding: 5px 90px 5px 90px;
    width: 100%;
    background-color: #222325;
    z-index: 10;
    clear: both;
    position: relative;
}

.copyright{
  height: 50px;
}

/* ABOUT */
.z99{
    z-index: 99;
}

.z100{
    z-index: 100;
}

.profile-img{
    width: 120%;
}

.profile-img img{
    width: 100%;
}

.profile-description{
    background-color: #f2f2f2;
    padding: 10%;
    box-sizing: border-box;
    text-align: left;
    color: rgb(55 50 105);
}

/* ARTWORK */

/* This is needed temporarily until we switch away frm stack-grid. This lib doesn't seem to play nice with sticky footers. */
.height-hack{
    min-height: 850px;
}

.album-title{
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.album-description{
    text-align: center;
    margin: auto;
    color: #3e3e3e;
}

.img-hover-zoom {
    overflow: hidden; 
    display: inline-block;
    position: relative;
}
  
.img-hover-zoom .img-to-zoom {
    transition: transform 0.9s ease, opacity 0.7s ease;
    object-fit: cover;
}

.img-hover-zoom:hover .img-to-zoom {
    transform: scale(1.15);
    opacity: 0.3;
}

.overlay-title{
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 22px;
}

.img-hover-zoom:hover .overlay-title {
    opacity: 1;
}

.overlay-title {
    transition: opacity 0.8s ease;
}

/*Contact*/
.right {
    text-align: right;
}

.left {
    text-align: left;
}

.contact {
    margin-top: 20px;
    background: white;
    padding: 20px;
    display: inline-block;
}

.contact_info {
    padding: 10px;
}

.contact_info a, .contact_info div {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 5px;
    border: solid 1px rgb(231, 231, 231);
    background: rgba(234, 237, 255, 0.7);
    margin-bottom: 10px;
    border-radius: 2px;
    opacity: 0.9;
    transition: opacity .10s ease-out;
}

.contact_info a:hover {
    opacity: 1;
}

.contact_info p, span, img {
    display: inline-block;
    vertical-align: middle;
    color: rgb(0, 0, 0);
}

.contact_info .title {
    width: 15%;
    font-weight: bold;
    color: grey;
}

.contact_info .title img {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.contact_info .value {
    width: 60%;
}
